@tailwind base;

@tailwind components;

@tailwind utilities;

@import url("https://rsms.me/inter/inter.css");

@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/variable/pretendardvariable-dynamic-subset.css");

@import url("https://use.typekit.net/mmp5tll.css");

@font-face {
	font-family: "Rucksack";
	src: url("assets/fonts/Rucksack/rucksack.woff2") format("woff2");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "Rucksack";
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Mont";
	src: url("assets/fonts/Mont/Mont ExtraLight DEMO.otf") format("opentype");
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Mont";
	src: url("assets/fonts/Mont/Mont Heavy DEMO.otf") format("opentype");
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "NanumSquare";
	src: url("assets/fonts/NanumSquare/NanumSquareR.ttf") format("truetype");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Urbane";
	src: url("assets/fonts/Urbane/Urbane-DemiBold.woff") format("woff");
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

html {
	scroll-behavior: smooth;
}

body {
	margin: 0;
	/* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

* {
	-webkit-tap-highlight-color: transparent;
}

button:focus,
a:focus {
	outline: none;
}

button::-moz-focus-inner {
	border: 0;
}
