@keyframes marquee {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(-50%);
	}
}

.animate-marquee {
	animation: marquee 20s linear infinite;
}

.text-outline {
	color: transparent;
	-webkit-text-stroke: 2px #d43031;
	text-stroke: 2px #d43031;
}
