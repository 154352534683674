.banner-swiper {
	position: relative;
}

.banner-swiper .swiper-pagination {
	bottom: 50px !important;
	display: flex;
	justify-content: end;
	align-items: center;
	gap: 12px;
	width: 85% !important;
	margin-left: auto;
}

.banner-swiper .swiper-pagination-bullet {
	width: 14px !important;
	height: 14px !important;
	margin: 3px !important;
	background: white !important;
	opacity: 1 !important;
	position: relative;
	transition: all 0.3s ease;
	border: 1px solid transparent !important;
}

.banner-swiper .swiper-pagination-bullet-active {
	background: transparent !important;
	border-radius: 100%;
	padding: 3px;
	width: 20px !important;
	height: 20px !important;
	border: 1px solid #d2191a !important;
}

.banner-swiper .swiper-pagination-bullet-active .bullet-content {
	border: none !important;
	background-color: #d2191a;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}

/*  모바일 대응  */
@media (max-width: 478px) {
	.banner-swiper .swiper-pagination {
		bottom: 20px !important;
	}

	.banner-swiper .swiper-pagination-bullet {
		width: 12px !important;
		height: 12px !important;
	}

	.banner-swiper .swiper-pagination-bullet-active .bullet-content {
		transform: scale(2);
	}
}
